<template>
  <transition name="fade">
    <form @keydown="form.onKeydown($event)" @submit.prevent="setRelocateItems">

      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input ml-" type="checkbox" id="add_car" v-model="form.add_car"
                     v-bind:id="form.add_car">
              <label for="add_car" class="custom-control-label"> Добавлено новое авто</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input ml-" type="checkbox" id="change_car_price" v-model="form.change_car_price"
                     v-bind:id="form.change_car_price">
              <label for="change_car_price" class="custom-control-label"> Изменена стоимость авто</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input ml-" type="checkbox" id="edit_car" v-model="form.edit_car"
                     v-bind:id="form.edit_car">
              <label for="edit_car" class="custom-control-label"> Обновлено авто</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input ml-" type="checkbox" id="responsible_car" v-model="form.responsible_car"
                     v-bind:id="form.responsible_car">
              <label for="responsible_car" class="custom-control-label"> Назначение ответственным за авто</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input ml-" type="checkbox" id="no_task_activity" v-model="form.no_task_activity"
                     v-bind:id="form.no_task_activity">
              <label for="no_task_activity" class="custom-control-label"> По задаче отсутствуют действия длительное время</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input ml-" type="checkbox" id="task_timeout" v-model="form.task_timeout"
                     v-bind:id="form.task_timeout">
              <label for="task_timeout" class="custom-control-label"> Истекает время выполнения задачи</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input ml-" type="checkbox" id="task_personal_timeout" v-model="form.task_personal_timeout"
                     v-bind:id="form.task_personal_timeout">
              <label for="task_personal_timeout" class="custom-control-label"> Истекает время выполнения личной задачи</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input ml-" type="checkbox" id="task_away_bonuses" v-model="form.task_away_bonuses"
                     v-bind:id="form.task_away_bonuses">
              <label for="task_away_bonuses" class="custom-control-label"> Списание за просрочку звонка</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input ml-" type="checkbox" id="car_comment" v-model="form.car_comment"
                     v-bind:id="form.car_comment">
              <label for="car_comment" class="custom-control-label"> Новый комментарий по авто</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input ml-" type="checkbox" id="car_remark" v-model="form.car_remark"
                     v-bind:id="form.car_remark">
              <label for="car_remark" class="custom-control-label"> Внесено примечание по звонку (воронка продаж)</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input ml-" type="checkbox" id="add_task" v-model="form.add_task"
                     v-bind:id="form.add_task">
              <label for="add_task" class="custom-control-label"> Создана новая задача</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input ml-" type="checkbox" id="edit_task" v-model="form.edit_task"
                     v-bind:id="form.edit_task">
              <label for="edit_task" class="custom-control-label"> Обновлена задача</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input ml-" type="checkbox" id="change_status_task" v-model="form.change_status_task"
                     v-bind:id="form.change_status_task">
              <label for="change_status_task" class="custom-control-label"> Изменен статус задачи</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input ml-" type="checkbox" id="new_responsible_task" v-model="form.new_responsible_task"
                     v-bind:id="form.new_responsible_task">
              <label for="new_responsible_task" class="custom-control-label"> Назначен ответственным по задаче</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input ml-" type="checkbox" id="old_responsible_task" v-model="form.old_responsible_task"
                     v-bind:id="form.old_responsible_task">
              <label for="old_responsible_task" class="custom-control-label"> Снят как ответственный по задаче</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input ml-" type="checkbox" id="client_recall_task" v-model="form.client_recall_task"
                     v-bind:id="form.client_recall_task">
              <label for="client_recall_task" class="custom-control-label"> Повторный звонок от клиента по задаче</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input ml-" type="checkbox" id="task_comment" v-model="form.task_comment"
                     v-bind:id="form.task_comment">
              <label for="task_comment" class="custom-control-label"> Новый комментарий по задаче</label>
            </div>
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input ml-" type="checkbox" id="car_sold" v-model="form.car_sold"
                     v-bind:id="form.car_sold">
              <label for="car_sold" class="custom-control-label"> Авто продано</label>
            </div>
          </div>
        </div>
      </div>

      <br />

      <Button :disabled="form.busy"
              :form="form"
              :loading="form.busy"
              class="btn btn-lg btn-success pull-right"
              style="min-width: 100px;">
        <i class="fa fa-save"></i>
      </Button>
    </form>
  </transition>
</template>

<script>
import Form from "vform";
import Multiselect from "vue-multiselect";

Form.axios = API.apiClient;

export default {
  name: "set-notification-channels-form",

  components: {
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
  },

  props: {
    employee_id: {
      type: Number,
      required: true,
    },
    employee_notification_channels: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    employees: [],
    responsible: null,

    form: new Form({
      add_car: true,
      change_car_price: true,
      edit_car: true,
      responsible_car: true,
      no_task_activity: true,
      task_timeout: true,
      task_personal_timeout: true,
      task_away_bonuses: true,
      car_comment: true,
      car_remark: true,
      add_task: true,
      edit_task: true,
      change_status_task: true,
      new_responsible_task: true,
      old_responsible_task: true,
      client_recall_task: true,
      task_comment: true,
      car_sold: true,
    }),
  }),

  async created() {
    try {
      this.form.add_car = this.employee_notification_channels.add_car;
      this.form.change_car_price = this.employee_notification_channels.change_car_price;
      this.form.edit_car = this.employee_notification_channels.edit_car;
      this.form.responsible_car = this.employee_notification_channels.responsible_car;
      this.form.no_task_activity = this.employee_notification_channels.no_task_activity;
      this.form.task_timeout = this.employee_notification_channels.task_timeout;
      this.form.task_personal_timeout = this.employee_notification_channels.task_personal_timeout;
      this.form.task_away_bonuses = this.employee_notification_channels.task_away_bonuses;
      this.form.car_comment = this.employee_notification_channels.car_comment;
      this.form.car_remark = this.employee_notification_channels.car_remark;
      this.form.add_task = this.employee_notification_channels.add_task;
      this.form.edit_task = this.employee_notification_channels.edit_task;
      this.form.change_status_task = this.employee_notification_channels.change_status_task;
      this.form.new_responsible_task = this.employee_notification_channels.new_responsible_task;
      this.form.old_responsible_task = this.employee_notification_channels.old_responsible_task;
      this.form.client_recall_task = this.employee_notification_channels.client_recall_task;
      this.form.task_comment = this.employee_notification_channels.task_comment;
      this.form.car_sold = this.employee_notification_channels.car_sold;
    } catch (error) {
      errorHandler(error);
    }
  },

  computed: {

  },

  methods: {
    async setRelocateItems() {
      await this.form.put("/employees/notification_channels/" + this.employee_id).then(() => {
        showSuccess();
        window.location.reload();
      }).catch((error) => {
        errorHandler(error);
      });
    },
  },
};
</script>

<style>

</style>
